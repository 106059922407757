<template>
<div>
  <p class="text-center">
    The dashboard/dashboard designer is currently being worked on and will be available for BETA testing shortly.
  </p>
  <p>
    Dashboards/ dashboard designer will offer..
  </p>
  <ul>
    <li>- A list of dashboards on the left with preview on the right and click through. </li>
    <li>- <router-link :to="{name: 'dashboarddesigner'}">Dashboard designer HERE</router-link></li>
  </ul>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>